import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import texts from '../../common/texts.json'
import { StoreFront } from '../CustomSVGIcons'

import './index.css'
import useWindowSize from '../../hooks/useWindowSize'

const lider = texts.COMMON.SOLD_BY_WALMART_LABEL
const [liderFilter] = texts.COMMON.SOLD_BY_WALMART_LABEL.split('.')

const getLinkTo = (facet, sellerName, seller) =>
  `/search?query=&page=1&refinementList[${facet || 'filters.Seller'}][]=${
    sellerName ? encodeURIComponent(seller) : liderFilter
  }`

const ProductSeller = ({
  isWfsProduct,
  sellerName,
  facet,
  setModalIsOpen,
  showIcon,
  showDetails,
  customClass,
  showTooltip,
  alwaysShowTwoLines,
  showSoldBySellerNameLink,
  setActualSellerName,
}) => {
  const { width } = useWindowSize()

  const isXsScreen = width < 350
  const isMdScreen = width < 815

  const seller = sellerName || lider
  const linkTo = getLinkTo(facet, sellerName, seller)
  const sellerIsLider = seller === lider
  const showDeliveredByWalmart = isWfsProduct && !sellerIsLider

  const wfsDisclaimerDetailsButton = () =>
    showDetails && (
      <Link
        className="new-product-sold-by__link--disclaimer"
        to="#"
        onClick={(e) => {
          e.preventDefault()
          setModalIsOpen(true)
          setActualSellerName(seller)
        }}
      >
        {texts.COMMON.MORE_DETAILS}
      </Link>
    )

  const walmartSpark = () => (
    <img
      src="/catalogo/images/sparkNoImage.svg"
      width={15}
      height={17}
      style={{ verticalAlign: 'sub', display: 'inline-block' }}
      alt={texts.COMMON.ALT_WALMART_SPARK}
    />
  )

  const deliveredByWalmart = (removeMargin) => {
    return (
      !sellerIsLider &&
      isWfsProduct && (
        <>
          <p className={`new-product-sold-by__text--bold ${removeMargin ? 'm-0' : ''}`}>
            {texts.COMMON.DELIVERED_BY_WALMART_LABEL} {walmartSpark()}
          </p>
        </>
      )
    )
  }

  const soldBySellerNameText = () => <p className="new-product-sold-by__text--normal">{seller}</p>

  const soldBySellerNameLink = (showTooltip = false) => {
    return (
      <Link className={`new-product-sold-by__link--seller ${showTooltip ? 'wfs-joyride' : ''}`} to={linkTo}>
        {seller}
      </Link>
    )
  }

  const showSellerNameLink = (isXsScreenAndAlwaysShowTwoLines = false) => {
    if (showSoldBySellerNameLink) {
      return isXsScreenAndAlwaysShowTwoLines ? soldBySellerNameLink(showTooltip) : soldBySellerNameLink()
    }
    return soldBySellerNameText()
  }

  const showWalmartSpark = () => sellerIsLider && walmartSpark()

  const getDeliveredByWalmart = (isXsScreenAndAlwaysShowTwoLines = false, isDeliveredByWalmart = false) => {
    if (!showDeliveredByWalmart) {
      return null
    }
    return isXsScreenAndAlwaysShowTwoLines ? (
      <div className={`${showIcon ? 'margin-left-icon-size' : ''}`}>{deliveredByWalmart(isDeliveredByWalmart)}</div>
    ) : (
      deliveredByWalmart(isDeliveredByWalmart)
    )
  }

  const showWfsDisclaimerDetailsButton = (isXsScreenAndAlwaysShowTwoLines = false) => {
    if (!showDeliveredByWalmart) {
      return null
    }
    return isXsScreenAndAlwaysShowTwoLines ? (
      <div className={`${showIcon ? 'margin-left-icon-size' : ''}`}>{wfsDisclaimerDetailsButton()}</div>
    ) : (
      wfsDisclaimerDetailsButton()
    )
  }

  if (isXsScreen || alwaysShowTwoLines) {
    return (
      <>
        <div className={`${customClass} new-product-sold-by`}>
          <div className="d-flex new-product-sold-by__container">
            {showIcon && (
              <div className={`mr-2 new-product-sold-by__container__icon--${sellerIsLider ? 'lider' : 'seller'}`}>
                <StoreFront size="small" />
              </div>
            )}

            <div className={`new-product-sold-by__container__message`}>
              <div className="new-product-sold-by__container__message__text">
                <p className="new-product-sold-by__text--normal">{texts.COMMON.SOLD_BY}</p>

                {showSellerNameLink(true)}
                {showWalmartSpark()}
              </div>
            </div>
          </div>

          {getDeliveredByWalmart(true, true)}
          {showWfsDisclaimerDetailsButton(true)}
        </div>
      </>
    )
  }

  if (isMdScreen) {
    return (
      <div className={`${customClass} new-product-sold-by`}>
        <div className="d-flex new-product-sold-by__container">
          {showIcon && (
            <div className={`mr-2 new-product-sold-by__container__icon--${sellerIsLider ? 'lider' : 'seller'}`}>
              <StoreFront size="small" />
            </div>
          )}

          <div className={` new-product-sold-by__container__message`}>
            <div className="new-product-sold-by__container__message__text">
              <p className="new-product-sold-by__text--normal">{texts.COMMON.SOLD_BY}</p>

              {showSellerNameLink()}
              {showWalmartSpark()}
              {getDeliveredByWalmart(false, true)}
            </div>
          </div>
        </div>

        {showWfsDisclaimerDetailsButton(true)}
      </div>
    )
  }

  return (
    <div className={`${customClass} new-product-sold-by`}>
      <div className="new-product-sold-by__container d-flex">
        {showIcon && (
          <div className={`mr-2 new-product-sold-by__container__icon--${sellerIsLider ? 'lider' : 'seller'}`}>
            <StoreFront size="small" />
          </div>
        )}

        <div className={`new-product-sold-by__container__message`}>
          <div className="new-product-sold-by__container__message__text">
            <p className="new-product-sold-by__text--normal">{texts.COMMON.SOLD_BY}</p>

            {showSellerNameLink()}
            {showWalmartSpark()}
            {getDeliveredByWalmart()}
            {showWfsDisclaimerDetailsButton()}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductSeller.defaultProps = {
  isWfsProduct: false,
  sellerName: '',
  facet: '',
  setModalIsOpen: () => {},
  showIcon: true,
  showDetails: true,
  customClass: '',
  showTooltip: false,
  alwaysShowTwoLines: false,
  showSoldBySellerNameLink: true,
  setActualSellerName: () => {},
}

ProductSeller.propTypes = {
  isWfsProduct: PropTypes.bool,
  sellerName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  facet: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setModalIsOpen: PropTypes.func,
  showIcon: PropTypes.bool,
  showDetails: PropTypes.bool,
  customClass: PropTypes.string,
  showTooltip: PropTypes.bool,
  alwaysShowTwoLines: PropTypes.bool,
  showSoldBySellerNameLink: PropTypes.bool,
  setActualSellerName: PropTypes.func,
}

export default ProductSeller
